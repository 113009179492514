import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"create_comments"},[_c(VForm,[_c(VContainer,[_c('h1',[_vm._v("Page Scripts")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Home Page Script","outlined":""},model:{value:(_vm.form_data.home_script),callback:function ($$v) {_vm.$set(_vm.form_data, "home_script", $$v)},expression:"form_data.home_script"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Thank you Page Script","outlined":""},model:{value:(_vm.form_data.thankyou_script),callback:function ($$v) {_vm.$set(_vm.form_data, "thankyou_script", $$v)},expression:"form_data.thankyou_script"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Function Home Page","outlined":""},model:{value:(_vm.form_data.home_function),callback:function ($$v) {_vm.$set(_vm.form_data, "home_function", $$v)},expression:"form_data.home_function"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Function Thank You Page","outlined":""},model:{value:(_vm.form_data.thankyou_function),callback:function ($$v) {_vm.$set(_vm.form_data, "thankyou_function", $$v)},expression:"form_data.thankyou_function"}})],1),_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading_form,"disabled":_vm.loading_form,"color":"blue-grey","outlined":""},on:{"click":_vm.create_script}},[_vm._v(" Add "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }