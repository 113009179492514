<template>
  <section class="create_comments">
    <v-form>
      <v-container>
        <h1>Page Scripts</h1>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.home_script"
                placeholder="Home Page Script"
                outlined
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.thankyou_script"
                placeholder="Thank you Page Script"
                outlined
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.home_function"
                placeholder="Function Home Page"
                outlined
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.thankyou_function"
                placeholder="Function Thank You Page"
                outlined
            ></v-text-field>
          </v-col>

          <v-btn
              :loading="loading_form"
              :disabled="loading_form"
              color="blue-grey"
              outlined
              class="ma-2 white--text"
              @click="create_script"
          >
            Add
            <v-icon
                right
                dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>

        </v-row>
      </v-container>
    </v-form>
  </section>
</template>

<script>
import {change_script} from "@/api/admin";

export default {
  data: () => ({
    loading_form: false,
    files: [],
    form_data: {}
  }),
  methods: {
    create_script() {
      this.loading_form = true;
      change_script(this.form_data)
      .then(res => {
        alert('script  added')
        this.loading_form = false;
      }).catch(() => {});
    }
  }
}
</script>